import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { Link } from "gatsby"
const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="big-title mb-5">
      <div className="container text-center">
        <h1> không tìm thấy trang</h1>
      </div>
    </div>
    <div className="container pt-5 pb-5 text-center error-page" >
      <h3>Xin Lỗi</h3>
      <p>Đã xảy ra lỗi, không tìm thấy trang bạn Yêu cầu!</p>
      <div className="error-actions">
        <Link to="/" >Về trang chủ</Link>
        <Link to="/lien-he" >Liên hệ</Link>
      </div>

    </div>
  </Layout>
)

export default NotFoundPage
